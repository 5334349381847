import React, { useEffect, useState, useMemo } from 'react';
import {
    Drawer,
    Slide,
    Box,
    Stack,
    IconButton,
    useTheme,
    Typography,
    CircularProgress,
    circularProgressClasses,
} from '@mui/material';
import { KeyboardArrowRightOutlined, KeyboardArrowLeft } from '@mui/icons-material';
import userguideArrow from 'assets/img/userguide-arrow.svg';
import AppInput from './AppInput';
import { useSelector, useDispatch, connect } from 'react-redux';
import { GetOneArticle, UserguideHelpful, GetAllArticles } from 'store/actions/articlesActions';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const LoadingProgress = ({ color, styles }) => {
    return (
        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
                color,
                animationDuration: '550ms',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                ...styles,
            }}
            size={20}
            thickness={4}
        />
    );
};

const AppArticle = (props) => {
    const theme = useTheme();
    const { open, handleClose, title, anchor, headerId, all_articles } = props;

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [openFeedback, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();

    const state = useSelector((state) => state?.articlesReducers) ?? {};
    const { all_merchant_articles, one_article, one_article_loading, loading: articles_loading } = state;

    const getOneArticle = async (id) => {
        await dispatch(GetOneArticle(id));
    };
    const getAllArticles = async (id) => {
        headerId ? await dispatch(GetAllArticles(id)) : null;
    };
    const userguideHelpful = async (payload) => {
        setLoading(true);
        const res = await dispatch(UserguideHelpful(payload));
        if (res?.success) {
            setLoading(false);
            toast.success('Feedback Sent Successfully!');
            setMessage('Thanks for the feedback!');
            setTimeout(() => {
                setOpen(false);
                setMessage(null);
            }, 2000);
        } else if (!res?.success) {
            setLoading(false);
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const navigateBack = () => {
        // setOpen(true);
        setPage(1);
    };

    useEffect(() => {
        if (page !== 1 && !one_article_loading) {
            setLoading(false);
            setMessage(null);
            setOpen(true);
        }
    }, [page, one_article_loading]);

    useEffect(() => {
        if (headerId) {
            getAllArticles(headerId);
        }
    }, [headerId]);

    const merchant_articles = useMemo(() => {
        if (title === 'User Guide') {
            return all_merchant_articles?.filter((article) =>
                article.title?.toLowerCase().includes(query.toLowerCase())
            );
        }

        return headerId
            ? all_articles?.filter((article) => article.title?.toLowerCase().includes(query.toLowerCase()))
            : [];
    }, [query, headerId, all_articles, all_merchant_articles]);

    return (
        <Drawer
            open={open}
            anchor={anchor || 'right'}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: { sm: '85vw', md: '500px' },
                    maxWidth: '500px',
                    flex: 1,
                    overflowY: 'scroll',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <Box>
                {title && (
                    <Stack
                        sx={{
                            px: 2,
                            py: 1.5,
                            width: '100%',
                            borderBottom: '1px solid #E1E2EC',
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={3}>
                            <IconButton
                                onClick={() => {
                                    page === 1 ? handleClose() : navigateBack();
                                }}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                            <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary[900] }}>
                                {page === 1 ? title : 'Back'}
                            </Typography>
                        </Stack>
                        {page === 1 ? (
                            <Box sx={{ width: '170px', height: '40px', mt: '-40px' }}>
                                <AppInput
                                    placeholder="Search..."
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                            </Box>
                        ) : null}
                    </Stack>
                )}
            </Box>
            {page === 1 ? (
                <Box>
                    <Box sx={{ flex: 1 }}>
                        <Stack>
                            {articles_loading ? (
                                <LoadingProgress color={theme.palette.primary[30]} styles={{ m: 2 }} />
                            ) : (
                                <>
                                    {merchant_articles?.length === 0 && (
                                        <Typography sx={{ fontSize: 16, textAlign: 'left', p: 3 }}>
                                            No articles available
                                        </Typography>
                                    )}
                                    {merchant_articles?.map((article) => {
                                        return (
                                            <Stack
                                                key={article?.id}
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                sx={{
                                                    px: '16px',
                                                    py: '16px',
                                                    my: '8px',
                                                    border: `1px solid ${theme.palette.neutral[95]}`,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setPage(2);
                                                    getOneArticle(article?.id);
                                                }}
                                            >
                                                <Stack>
                                                    <Stack>
                                                        <Stack flexDirection="row">
                                                            <img src={userguideArrow} alt="arrow" width="16px" />
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                    fontWeight: 600,
                                                                    color: theme.palette.primary[40],
                                                                    mb: '8px',
                                                                    ml: '8px',
                                                                }}
                                                            >
                                                                {article?.title}
                                                            </Typography>
                                                        </Stack>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 11,
                                                                fontWeight: 400,
                                                                color: theme.palette.neutral[50],
                                                                ml: '24px',
                                                            }}
                                                        >
                                                            {article?.sub_title}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <KeyboardArrowRightOutlined />
                                            </Stack>
                                        );
                                    })}
                                </>
                            )}
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <>
                    {one_article_loading ? (
                        <LoadingProgress color={theme.palette.primary[30]} styles={{ m: 2 }} />
                    ) : (
                        <Stack sx={{ p: 2, pb: '70px' }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary[40] }}>
                                    {one_article?.title}
                                </Typography>
                                {/* <Typography sx={{ fontSize: 18, fontWeight: 500, color: theme.palette.neutral[600] }}>
                            {one_article?.sub_title}
                        </Typography> */}
                            </Box>
                            <Box
                                sx={{
                                    color: theme.palette.neutral[600],
                                    fontSize: '14px',
                                    '& img': {
                                        margin: 'auto',
                                    },
                                    '& ul': {
                                        paddingInline: 4,
                                        listStyle: 'disc',
                                        marginBottom: 0.5,
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: one_article?.body }}
                            ></Box>
                        </Stack>
                    )}
                </>
            )}
            {page != 1 && openFeedback && !one_article_loading && (
                <Stack
                    sx={{
                        position: 'fixed',
                        bottom: '0',
                        height: '65px',
                        p: '16px',
                        boxShadow: '0px -5px 16px 0px #5050500F',
                        background: 'white',
                        minWidth: { sm: '85vw', md: '500px' },
                        maxWidth: '500px',
                        overflow: 'hidden',
                    }}
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                >
                    {loading ? (
                        <LoadingProgress color={theme.palette.primary[30]} />
                    ) : (
                        <>
                            {message ? (
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        color: theme.palette.primary[30],
                                        mr: '6px',
                                    }}
                                >
                                    {message}
                                </Typography>
                            ) : (
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: theme.palette.primary[40],
                                            mr: '6px',
                                        }}
                                    >
                                        Was this informational?
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: theme.palette.primary[30],
                                            p: '6px',
                                            mr: '8px',
                                            border: `1px solid ${theme.palette.gray[100]}`,
                                            backgroundColor: theme.palette.gray[50],
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => userguideHelpful({ is_helpful: true, article: one_article?.id })}
                                    >
                                        Yes
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: theme.palette.primary[30],
                                            p: '6px',
                                            border: `1px solid ${theme.palette.gray[100]}`,
                                            backgroundColor: theme.palette.gray[50],
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            userguideHelpful({ is_helpful: false, article: one_article?.id })
                                        }
                                    >
                                        No
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Stack>
            )}
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_certificates: state?.auditReducers?.merchant_certificate,
        merchant_info: state?.merchantReducers?.merchant_info,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.audits_download,
        all_articles: state?.articlesReducers?.all_articles,
    };
};

export default connect(mapStateToProps)(AppArticle);
