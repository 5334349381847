import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
    DeleteOutlined,
    MoreOutlined,
    EyeOutlined,
    EditFilled,
    FileAddOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';

const { Item } = Menu;

const UploadedDocOperations = (props) => {
    const { record, openUpdateModal, deleteModal, requestForUpdate, openViewModal } = props;
    const menu = (
        <Menu className="tableaction">
            <Item key="0" onClick={() => openUpdateModal(record)}>
                <EditFilled /> Update Doc
            </Item>
            <Item key="1" onClick={() => openViewModal(record)}>
                <EyeOutlined /> View Response
            </Item>
            <Item
                key="2"
                onClick={() => requestForUpdate(record)}
                style={{ color: record?.update_needed ? 'green' : '' }}
                disabled={!record?.response_needed}
            >
                <FileAddOutlined /> Require update
            </Item>
            <a
                // key="preview"
                href={record?.file}
                target="_blank"
                rel="noreferrer"
                className="pl-[0.6rem] flex items-center gap-2"
            >
                <DownloadOutlined /> Download Doc
            </a>
            <Item key="4" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
                <DeleteOutlined /> Delete
            </Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, {})(UploadedDocOperations);
