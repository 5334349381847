import React, { useMemo, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TextSnippet, FileDownload, FileUpload } from '@mui/icons-material';
// import AppUserGuide from 'components/new_components/AppUserGuide';
// import { steps } from './steps';

// core components
import AppButton from 'components/new_components/AppButton';
import AppUploadButton from 'components/new_components/AppUploadButton';
import ItemSubheader from '../ItemSubheader';
import DocumentDrawer from './DocumentDrawer';

// redux
import { connect, useDispatch } from 'react-redux';
import { UploadMerchantDocument, ExemptMerchantDocument } from 'store/actions/complianceActions';

// utils
import { getResourceDetailsByStandard } from '../../utils';
import { accept } from 'validate';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import Download from 'assets/img/download01.svg';
import Dash from 'assets/img/Dash.svg';

// import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';
import PolicyHistoryModal from '../PolicyHistoryModal';
import UploadDocumentModal_two from '../UploadDocumentModal_two';
import { getType } from 'utils';
import { GetDocumentLink } from 'store/actions/complianceActions';
import LoadingState from 'components/new_components/LoadingState';

const { pdf, docx, excel, txt, ppt } = accept;

const DocumentItem = (props) => {
    const {
        document,
        all_merchant_documents,
        title_key,
        UploadMerchantDocument,
        ExemptMerchantDocument,
        standard,
        onResourceChange,
        // userguide_info,
        openTaskAssignModal,
        resource,
        taskInfo,
        assignedDocuments,
        openPolicyHistory,
        handleOpenPolicyHistory,
        handleClosePolicyHistory,
    } = props;
    const theme = useTheme();
    // state
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const [drawerOpenedTab, setDrawerOpenedTab] = useState(null);
    const [uploadDocumentModal, setUploadDocuementModal] = useState(false);
    const [loadingLink, setLoadingLink] = useState(false);
    const dispatch = useDispatch();
    // const [openUserGuide, setOpenUserGuide] = useState(false);

    const role = getType('role');
    //translation
    const { t } = useTranslation('compliance');

    // ref
    const inputRef = useRef();
    const stepsTargetRef = useRef([]);

    const openUploadModal = () => {
        setUploadDocuementModal(true);
    };

    const closeUploadModal = () => {
        setUploadDocuementModal(false);
    };

    // const { updateOneUserGuide } = useContext(UserGuideContext);

    // memo
    // get the current document in view
    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === document?.id);
    }, [document, all_merchant_documents]);

    // logic
    const openDrawer = (tab) => setDrawerOpenedTab(tab);
    const closeDrawer = () => setDrawerOpenedTab(null);

    //UserGuide
    // const OpenUserGuide = () => {
    //     setOpenUserGuide(true);
    // };
    // const CloseUserGuide = () => {
    //     updateOneUserGuide({ compliance: true });
    //     setOpenUserGuide(false);
    // };

    // async functions
    // update a document version
    const uploadMerchantDocument = async (e) => {
        // get the form data
        const doc = new FormData();
        doc.append('document', e.target.files[0]);
        doc.append('document_id', document?.id);

        // sending the data
        setLoading({ ...loading, upload: true });
        const res = await UploadMerchantDocument(doc);
        setLoading({ ...loading, upload: false });
        //handling response
        if (res?.success) {
            toast.success(res?.message);
            if (standard !== 'tech_sec') {
                onResourceChange();
            }
        } else {
            toast.error(res?.message);
        }
        event.target.value = null;
    };
    // exempt a document
    const exemptMerchantDocument = async () => {
        const payload = {
            document: document?.id,
            standard,
        };
        setLoading({ ...loading, exempt: true });
        const res = await ExemptMerchantDocument(payload);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            onResourceChange();
        } else {
            toast.error(res?.message);
        }
    };
    const newMenuitems = [
        !assignedDocuments?.includes(document?.id) && {
            label: 'Assign to',
            action: () =>
                openTaskAssignModal(resource, {
                    standard: standard === 'iso22301' ? 'is022301' : standard,
                    document: document?.id,
                    is_document: true,
                    ...taskInfo,
                }),
        },
        { label: 'View policy history', action: handleOpenPolicyHistory },
    ];

    const getDocumentLink = async () => {
        setLoadingLink(true);
        const res = await dispatch(GetDocumentLink('document', document?.id));
        setLoadingLink(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(selectedDocument)}
                exempting={loading.exempt}
                onExempt={exemptMerchantDocument}
                menuItems={
                    standard === 'soc2' || standard === 'sslc' || standard === 'iso9001'
                        ? newMenuitems
                        : [
                              { label: t('document'), action: () => openDrawer(0) },
                              !assignedDocuments?.includes(document?.id) &&
                                  role !== 'auditor' && {
                                      label: 'Assign to',
                                      action: () =>
                                          openTaskAssignModal(resource, {
                                              standard: standard === 'iso22301' ? 'is022301' : standard,
                                              document: document?.id,
                                              is_document: true,
                                              ...taskInfo,
                                          }),
                                  },
                              // { label: 'Comments', action: () => openDrawer(1) },
                          ]
                }
                assigned={assignedDocuments?.includes(document?.id)}
                resource="doc"
                standard={standard}
            />
            <Stack direction={'column'} justifyContent="flex-start" alignItems="flex-start" sx={{ py: 2 }} spacing={3}>
                {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Dash} alt="Dash" />

                        <Typography
                            sx={{
                                color: '#475569',
                                fontWeight: 500,
                                fontSize: 15,
                                ml: 1,
                            }}
                        >
                            {t('upload')} {document?.[title_key]}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextSnippet color="primary" />

                        <Typography
                            sx={{
                                color: '#475569',
                                fontWeight: 500,
                                fontSize: 15,
                                ml: 1,
                            }}
                        >
                            {t('upload')} {document?.[title_key]}
                        </Typography>
                    </Box>
                )}
                <Stack>
                    {(standard !== 'soc2' && standard !== 'sslc') ||
                        (standard === 'iso9001' && (
                            <Typography
                                sx={{
                                    color: theme.palette.gray[800],
                                    fontWeight: 500,
                                    fontSize: 16,
                                }}
                            >
                                {t('upload')} {document?.[title_key]}
                            </Typography>
                        ))}
                    {(standard !== 'soc2' && standard !== 'sslc') ||
                        (standard === 'iso9001' && (
                            <Typography
                                sx={{
                                    color: theme.palette.gray[500],
                                    fontWeight: 500,
                                    fontSize: 12,
                                }}
                            >
                                {t('click')}
                            </Typography>
                        ))}
                    {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' ? (
                        <Box sx={{ ml: 1.8, mt: -1 }}>
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#64748B',
                                    border: '2px solid #395BA9',
                                    width: 'inherit',
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'left',
                                    '&, &:hover': { color: '#64748B', border: '2px solid #395BA9' },
                                }}
                                disabled={role === 'auditor'}
                                onClick={openUploadModal}
                                startIcon={<img src={FileUnCheck} alt="FileUnCheck" />}
                            >
                                {selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                            </Button>

                            <AppButton
                                color="primary"
                                variant="text"
                                name={loadingLink ? <LoadingState size={20} /> : 'Download policy template'}
                                icon={<img src={Download} alt="Download" className="w-4" />}
                                disabled={role === 'auditor'}
                                onClick={() => getDocumentLink(document?.id)}
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#64748B',
                                    textDecoration: 'underline',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: -2,
                                    mt: 0.5,
                                    '&, &:hover': { color: '#64748B' },
                                }}
                                ref={(el) => (stepsTargetRef.current[0] = el)}
                            />
                        </Box>
                    ) : (
                        <Stack direction="row" spacing={3}>
                            <AppButton
                                color="primary"
                                variant="contained"
                                name={loadingLink ? <LoadingState size={20} /> : t('downloadTemplate')}
                                icon={<FileDownload />}
                                onClick={() => getDocumentLink(document?.id)}
                                disabled={role === 'auditor'}
                                sx={{
                                    textTransform: 'uppercase',
                                    '&, &:hover': { color: theme.palette.white.main },
                                }}
                                ref={(el) => (stepsTargetRef.current[0] = el)}
                            />
                            <AppUploadButton
                                color="primary"
                                variant="outlined"
                                label={selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                                startIcon={<FileUpload />}
                                loading={loading.upload}
                                id={document?.[title_key]}
                                onChange={uploadMerchantDocument}
                                disabled={role === 'auditor'}
                                accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                                sx={{ textTransform: 'uppercase' }}
                                inputRef={inputRef}
                                ref={(el) => (stepsTargetRef.current[1] = el)}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <DocumentDrawer
                open={Boolean(drawerOpenedTab !== null)}
                tab={drawerOpenedTab}
                handleClose={closeDrawer}
                document={getResourceDetailsByStandard(selectedDocument, standard)}
                onResourceChange={onResourceChange}
                standard={standard}
            />
            {/* <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            /> */}
            <UploadDocumentModal_two
                open={uploadDocumentModal}
                handleClose={closeUploadModal}
                doc_id={document?.id}
                standard={standard}
            />
            <PolicyHistoryModal
                open={openPolicyHistory}
                handleClose={handleClosePolicyHistory}
                selected={selectedDocument}
                document={getResourceDetailsByStandard(selectedDocument, standard)}
                doc_id={document?.id}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        // userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
    };
};
export default connect(mapStateToProps, {
    UploadMerchantDocument,
    ExemptMerchantDocument,
})(DocumentItem);
