import {
    ALL_AUDITORS,
    ALL_MERCHANTS,
    ALL_AUDITOR_MERCHANTS,
    ALL_FORM_FIELDS,
    ALL_GDPA_CATEGORIES,
    ALL_KDPA_CATEGORIES,
    ALL_ADMIN_DOCUMENTS_BY_TAGS,
    ALL_ADMIN_DOCUMENTS_BY_ALTTAGS,
    ALL_ANNEXES,
    ALL_ANNEX_CATEGORIES,
    ALL_ANNEXES_TWO,
    ALL_ANNEXES_NEW,
    ALL_ANNEXTWO_CATEGORIES,
    ALL_ANNEXNEW_CATEGORIES,
    ALL_ISO27001_DOCUMENTS,
    ALL_ISO27001_2_DOCUMENTS,
    ALL_ISONEW_DOCUMENTS,
    ALL_ISO22301_DOCUMENTS,
    ALL_ADMIN_ISO_FORMS,
    ALL_ADMIN_ISOTWO_FORMS,
    ALL_ADMIN_ISONEW_FORMS,
    ALL_ADMIN_CONTINUITY_ISO_FORMS,
    ALL_ONBOARDING_ITEMS,
    ALL_THREATS,
    ALL_THREATS_CATEGORIES,
    ALL_RISK_CATEGORIES,
    ALL_VULNERABILITIES,
    ALL_CONTROLS,
    ALL_ADMIN_DOCUMENTS_BY_CONTROL,
    ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT,
    ALL_ADMIN_FORMS_BY_CONTROL,
    ALL_ADMIN_FORMS_BY_MANAGEMENT,
    ALL_SUPPORT_USERS,
    ALL_RELEASES,
    ALL_DOCUMENTS_BY_CONTROL_ID,
    ALL_INTEGRATIONS_GROUPS,
    ALL_INTEGRATIONS_BY_GROUP,
    INTEGRATION_BY_ID,
    ALL_ISOGROUPS,
    ALL_MANAGEMENT_CLAUSES,
    ALL_ISOGROUPSTWO,
    ALL_MANAGEMENT_CLAUSES_TWO,
    ALL_ISOGROUPS_NEW,
    ALL_MANAGEMENT_CLAUSES_NEW,
    ALL_CYBERSECURITIES,
    ALL_PROJECT_MANAGEMENT,
    ALL_CLOUD_SECURITIES,
    ALL_CONTINUITY_CLAUSES,
    ALL_FAQS,
    ALL_GLOSSARY,
    ALL_TESTIMONIALS,
    ALL_CRITERIA,
    ALL_ADMIN_BLOCKCHAIN_FORMS,
    ALL_SUBCRITERIA_DOCUMENT,
    ALL_SUBCRITERIA_FORMS,
    CONDITIONAL_BY_ID,
    MERCHANT_BY_ID,
    ALL_CONDITIONAL_ITEMS,
    DOCUMENTS_BY_SUBCRITERIA,
    ALL_TOOLS_ITEMS,
    ALL_BACKGROUND_CHECK_TOOLS,
    ALL_USER_TOOLS_BY_ID,
    ALL_PUSH_NOTIFICATIONS,
    CODEREVIEW_DOCUMENTS,
    ALL_USERS,
    ALL_SURVEY,
    ALL_PENTESTS,
    ALL_SURVEY_RESPONSE,
    ALL_EMPLOYEES,
    ADMIN_UPLOADED_DOCS,
    MERCHANT_EXTRA_DOCS,
    ALL_FORMS_BY_TAG,
    ALL_DOCUMENTS_BY_TAG,
    ALL_KDPA_DOCUMENTS,
    ALL_GDPA_DOCUMENTS,
    ALL_ADMIN_KDPA_FORMS,
    ALL_ADMIN_GDPA_FORMS,
    ALL_SECTOR,
    ALL_CII_TOOLS_ITEMS,
    ALL_SUBSECTOR_DOCUMENT,
    ALL_ADMIN_CII_FORMS,
    ALL_CII_CONDITIONAL_ITEMS,
    ALL_USER_CII_TOOLS,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS,
    ALL_ADMIN_DOCUMENTS_BY_SUBREQ,
    ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ,
    ALL_ADMIN_PCIDSS_FORMS,
    ALL_ADMIN_PCIDSS4POINT0_FORMS,
    ALL_PCIDSS4POINT0_REQUIREMENTS,
    ALL_REQUIREMENTS,
    ALL_CATEGORIES,
    ALL_NDPR_DOCUMENTS,
    ALL_ADMIN_NDPR_FORMS,
    ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL,
    ALL_ADMIN_EVIDENCE_BY_CONTROL,
    ALL_ADMIN_EVIDENCE_BY_MANAGEMENT,
    ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL,
} from '../constants';
import axiosInstance from '../../utils/https';

import { GetAllCodeReview } from './merchantActions';

// auditors
export const GetAllAuditors = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/auditors/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDITORS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorDetails = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/auditors/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDITORS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateAuditorDetails = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/auditors/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditors());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const ApproveAnAuditor = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().put(`/user_mgt/approve_auditor/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditors());
        return {
            success: true,
            message: 'Auditor approved successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteAuditor = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/auditors/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditors());
        return {
            success: true,
            message: 'Deleted Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// merchants
export const GetAllMerchants = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteMerchant = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/merchants/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchants());
        return {
            success: true,
            message: 'Deleted Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantsByAuditorID = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/?auditor=${id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDITOR_MERCHANTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantsByID = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/${id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_BY_ID, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AssignAuditorToMerchant = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().put(`/user_mgt/assign_auditor/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditors());
        dispatch(GetAllMerchants());
        return {
            success: true,
            message: 'Auditor was assigned to a merchant.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateMerchantRecords = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchants());
        return {
            success: true,
            message: 'Plan was assigned to a merchant.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// export const AssignAuditorToMerchant =
//   (id, credentials) => async (dispatch) => {
//     try {
//       const res = await axiosInstance().put(`/user_mgt/assign_auditor/${id}/`, credentials);
//
//       if (res?.status?.toLowerCase() !== "success")
//         throw new Error(res?.message);
//       dispatch(GetAllAuditors());
//       dispatch(GetAllMerchants());
//       return {
//         success: true,
//         message: "Auditor was assigned to a merchant.",
//       };
//     } catch (err) {
//       return { success: false, message: err.message };
//     }
//   };

// Compliance

// PCIDSS
export const GetAllRequirements = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/requirements/?parent_requirement=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_REQUIREMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// PCIDSS 4.0
export const GetAllPcidss4Point0Requirements = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/card4/?parent_requirement=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PCIDSS4POINT0_REQUIREMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByCategory = (category_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?category=${category_id}&tags=ndpr`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_NDPR_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByCategory = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?category=${category}&tags=ndpr`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_NDPR_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
//SOC2
export const GetAllControls = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/common_control/?parent_control=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONTROLS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateControl = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/common_control/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllControls());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditControl = (credentials, control_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/common_control/${control_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllControls());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteControl = (control_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/common_control/${control_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllControls());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//GDPA
export const GetAllGdpaCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/gdpa_category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_GDPA_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateGdpaCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/gdpa_category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGdpaCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditGdpaCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/gdpa_category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGdpaCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteGdpaCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/gdpa_category/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGdpaCategories());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//KDPA
export const GetAllKdpaCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/kdpa_category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_KDPA_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateKdpaCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/kdpa_category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllKdpaCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditKdpaCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/kdpa_category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllKdpaCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteKdpaCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/kdpa_category/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllKdpaCategories());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//ISO27001
export const GetAllAnnexCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annex/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEX_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllAnnexes = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annex/?category=annex`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEXES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllIsogroups = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annex/?category=${category}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ISOGROUPS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllManagementClauses = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annex/?category=clause`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MANAGEMENT_CLAUSES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateAnnex = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/annex/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex' ? dispatch(GetAllAnnexes()) : dispatch(GetAllManagementClauses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditAnnex = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/annex/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex' ? dispatch(GetAllAnnexes()) : dispatch(GetAllManagementClauses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteAnnex = (id, category) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/annex/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        category === 'annex' ? dispatch(GetAllAnnexes()) : dispatch(GetAllManagementClauses());
        return {
            success: true,
            message: 'Deleted Successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//ISO27001-2022
export const GetAllAnnexTwoCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annextwo/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEXTWO_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllAnnexesTwo = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annextwo/?category=annex_two`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEXES_TWO, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllIsogroupsTwo = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/annextwo/?category=${category === 'clause' ? category : 'annex_two'}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ISOGROUPSTWO, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllManagementClausesTwo = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/annextwo/?category=clause`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MANAGEMENT_CLAUSES_TWO, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateAnnexTwo = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/annextwo/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex_two' ? dispatch(GetAllAnnexesTwo()) : dispatch(GetAllManagementClausesTwo());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditAnnexTwo = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/annextwo/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex_two' ? dispatch(GetAllAnnexesTwo()) : dispatch(GetAllManagementClausesTwo());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteAnnexTwo = (id, category) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/annextwo/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        category === 'annex_two' ? dispatch(GetAllAnnexesTwo()) : dispatch(GetAllManagementClausesTwo());
        return {
            success: true,
            message: 'Deleted Successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//ISONEW
export const GetAllAnnexNewCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEXNEW_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllAnnexesNew = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=annex`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ANNEXES_NEW, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllIsogroupsNew = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=${category}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ISOGROUPS_NEW, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllManagementClausesNew = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=clause`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MANAGEMENT_CLAUSES_NEW, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCybersecurities = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=cybersecurity`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CYBERSECURITIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllProjectManagements = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=project_management`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PROJECT_MANAGEMENT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCloudSecurities = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=cloud_security`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CLOUD_SECURITIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateAnnexNew = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/tech_sec/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex'
            ? dispatch(GetAllAnnexesNew())
            : credentials?.category === 'clause'
            ? dispatch(GetAllManagementClausesNew())
            : credentials?.category === 'cybersecurity'
            ? dispatch(GetAllCybersecurities())
            : credentials?.category === 'project_management'
            ? dispatch(GetAllProjectManagements())
            : dispatch(GetAllCloudSecurities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditAnnexNew = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/tech_sec/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        credentials?.category === 'annex'
            ? dispatch(GetAllAnnexesNew())
            : credentials?.category === 'clause'
            ? dispatch(GetAllManagementClausesNew())
            : credentials?.category === 'cybersecurity'
            ? dispatch(GetAllCybersecurities())
            : credentials?.category === 'project_management'
            ? dispatch(GetAllProjectManagements())
            : dispatch(GetAllCloudSecurities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteAnnexNew = (id, category) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/tech_sec/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        category === 'annex'
            ? dispatch(GetAllAnnexesNew())
            : category === 'clause'
            ? dispatch(GetAllManagementClausesNew())
            : category === 'cybersecurity'
            ? dispatch(GetAllCybersecurities())
            : category === 'project_management'
            ? dispatch(GetAllProjectManagements())
            : dispatch(GetAllCloudSecurities());
        return {
            success: true,
            message: 'Deleted Successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// B L O C K C H A I N
export const GetAllCriteria = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/criteria/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CRITERIA, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateCriteria = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/criteria/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateCriteria = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/criteria/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCriteria = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/criteria/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateSubcriteria = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/sub_criteria/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
            subcriteria_id: res?.data?.id,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateSubcriteria = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/sub_criteria/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteSubriteria = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/sub_criteria/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCriteria());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// CI DIRECTIVE
export const GetAllSector = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/cii_sector/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SECTOR, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateSector = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cii_sector/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateSector = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/cii_sector/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteSector = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/cii_sector/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateSubsector = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cii_subsector/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
            subcriteria_id: res?.data?.id,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateSubsector = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/cii_subsector/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteSubsector = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/cii_subsector/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSector());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCiiToolsItems = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/cii_tool/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_CII_TOOLS_ITEMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateCiiToolsItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cii_tool/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditCiiToolsItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/cii_tool/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCiiToolsItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/cii_tool/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllCiiConditionalItems = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/cii_conditional/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_CII_CONDITIONAL_ITEMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateCiiCondtionalItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cii_conditional/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditCiiCondtionalItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/cii_conditional/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteCiiCondtionalItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/cii_conditional/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// ISO22301
export const GetAllContinuityClauses = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/continuity_management`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONTINUITY_CLAUSES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateContinuityClause = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/continuity_management/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllContinuityClauses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditContinuityClause = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/continuity_management/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllContinuityClauses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteContinuityClause = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/continuity_management/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllContinuityClauses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//documents
export const GetDocumentsByControlID = (control_id) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?common_control=${control_id}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        const previousPayload = getState().adminReducers.all_documents_by_control_id;
        const newPayload = {
            ...previousPayload,
            [control_id]: res?.data,
        };
        dispatch({
            type: ALL_DOCUMENTS_BY_CONTROL_ID,
            payload: newPayload,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllDocumentsByTags = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?tags=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ADMIN_DOCUMENTS_BY_TAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllPcidss4Point0DocumentsByTags = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?tags=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllDocumentsByAltTag = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?not_tag=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ADMIN_DOCUMENTS_BY_ALTTAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPcidss4Point0DocumentsByAltTag = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?not_tag=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetDocumentsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/documents/?requirement_sub_no=${sub_requirement_no}&tags=pcidss`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_SUBREQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GeAllDocumentsByTag = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?&tags=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_DOCUMENTS_BY_TAG,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetPcidss4Point0DocumentsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?card4_sub_no=${sub_requirement_no}&tags=card4`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetDocumentsBySubcriteria = (subcriteria) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?subcriteria=${subcriteria}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: DOCUMENTS_BY_SUBCRITERIA,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByManagementClause = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?annex=${annex_id}&tags=iso27001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISO27001_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByManagementClauseTwo = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?annex=${annex_id}&tags=isms`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISO27001_2_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByManagementClauseNew = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?annex=${annex_id}&tags=tech_sec`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISONEW_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsBySubcontrol = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?sub_control=${subcontrol}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsBySubobjective = (sublifecycle) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?lifecycle_sub_no=${sublifecycle}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsBySubManagement = (sublifecycle) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?quality_mgt_sub_no=${sublifecycle}&tags=iso9001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsByControl = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?common_control=${subcontrol}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesBySubcontrol = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?sub_control=${subcontrol}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_EVIDENCE_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesBySubobjective = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?lifecycle_sub_no=${subcontrol}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_EVIDENCE_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesBySubmanagement = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?quality_mgt_sub_no=${subcontrol}&tags=iso9001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_EVIDENCE_BY_MANAGEMENT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesByControl = (control) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?common_control=${control}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByKdpaCategory = (category_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?kdpa_category=${category_id}&tags=kdpa`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_KDPA_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllDocumentsByGdpaCategory = (category_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?gdpa_category=${category_id}&tags=gdpa`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_GDPA_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllDocumentsByAnnex = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?annex=${annex_id}&tags=iso27001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISO27001_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByAnnexTwo = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?annex_two=${annex_id}&tags=isms`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISO27001_2_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByAnnexNew = (annex_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?tech_sec=${annex_id}&tags=tech_sec`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISONEW_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByContinuityIso = (clause_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?continuity_iso=${clause_id}&tags=continuity`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ISO22301_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsBySubcriteria = (subcriteria_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?subcriteria=${subcriteria_id}&tags=blockchain`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_SUBCRITERIA_DOCUMENT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllDocumentsBySubsector = (subsector_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?cii_subsector=${subsector_id}&tags=cii`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_SUBSECTOR_DOCUMENT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetDocumentByStandardAndGroup = (tag, groupId) => async (dispatch) => {
    if (tag === 'pcidss') {
        dispatch(GetDocumentsBySubRequirement(groupId));
    } else if (tag === 'card4') {
        dispatch(GetPcidss4Point0DocumentsBySubRequirement(groupId));
    } else if (tag === 'ndpr') {
        dispatch(GetAllDocumentsByCategory(groupId));
    } else if (tag === 'kdpa') {
        dispatch(GetAllDocumentsByKdpaCategory(groupId));
    } else if (tag === 'gdpa') {
        dispatch(GetAllDocumentsByGdpaCategory(groupId));
    } else if (tag === 'iso27001') {
        dispatch(GetAllDocumentsByAnnex(groupId));
    } else if (tag === 'isms') {
        dispatch(GetAllDocumentsByAnnexTwo(groupId));
    } else if (tag === 'tech_sec') {
        dispatch(GetAllDocumentsByAnnexNew(groupId));
    } else if (tag === 'soc2') {
        dispatch(GetDocumentsBySubcontrol(groupId));
    } else if (tag === 'blockchain') {
        dispatch(GetAllDocumentsBySubcriteria(groupId));
    } else if (tag === 'continuity') {
        dispatch(GetAllFormsByContinuity(groupId));
    } else if (tag === 'cii') {
        dispatch(GetAllDocumentsBySubsector(groupId));
    } else if (tag === 'sslc') {
        dispatch(GetDocumentsBySubobjective(groupId));
    } else if (tag === 'iso9001') {
        dispatch(GetDocumentsBySubManagement(groupId));
    }
    dispatch(GetAllDocumentsByAltTag(tag));
};

export const GetEvidenceByStandardAndGroup = (tag, title) => async (dispatch) => {
    if (tag === 'soc2') {
        dispatch(GetEvidencesBySubcontrol(title));
    } else if (tag === 'sslc') {
        dispatch(GetEvidencesBySubobjective(title));
    } else if (tag === 'iso9001') {
        dispatch(GetEvidencesBySubmanagement(title));
    }
};
export const CreateDocument = (credentials, groupId, tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/documents/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        if (groupId) {
            dispatch(GetDocumentByStandardAndGroup(tag, groupId));
        }
        return {
            success: true,
            message: res?.message,
            document: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditDocument = (credentials, id, tag, groupId) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/documents/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetDocumentByStandardAndGroup(tag, groupId));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteDocument = (doc_id, groupId, tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/documents/${doc_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetDocumentByStandardAndGroup(tag, groupId));
        dispatch(GetAllDocumentsByTags(tag));
        return {
            success: true,
            message: 'Document deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateEvidence = (credentials, tag, title) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/evidence/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetEvidenceByStandardAndGroup(tag, title));
        return {
            success: true,
            message: res?.message,
            document: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateEvidence = (credentials, id, tag, title) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/evidence/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetEvidenceByStandardAndGroup(tag, title));
        return {
            success: true,
            message: res?.message,
            document: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteEvidence = (id, tag, title) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/evidence/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetEvidenceByStandardAndGroup(tag, title));
        return {
            success: true,
            message: res?.message,
            document: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//forms
export const GetAllFormsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/forms/?requirement_sub_no=${sub_requirement_no}&tags=pcidss`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_PCIDSS_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormsByTags = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?tags=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_FORMS_BY_TAG,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllPcidss4Point0FormsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?card4_sub_no=${sub_requirement_no}&tags=card4`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_PCIDSS4POINT0_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetFormsBySubcontrol = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?sub_control=${subcontrol}&tags=soc2`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_FORMS_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFormsBySubcontrolObjectives = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?lifecycle_sub_no=${subcontrol}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_FORMS_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFormsByLifecycle = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?lifecycle_sub_no=${subcontrol}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_FORMS_BY_CONTROL,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFormsByManagement = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?quality_mgt_sub_no=${subcontrol}&tags=iso9001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_FORMS_BY_MANAGEMENT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsBySubCriteria = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?subcriteria=${sub_requirement_no}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_BLOCKCHAIN_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsBySubSector = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?cii_subsector=${sub_requirement_no}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_CII_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormsByKdpaCategory = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?kdpa_category=${category}&tags=kdpa`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_KDPA_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormsByGdpaCategory = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?gdpa_category=${category}&tags=gdpa`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_GDPA_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormsByAnnex = (annex) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?annex=${annex}&tags=iso27001`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_ISO_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            forms: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByAnnexTwo = (annex) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?annex_two=${annex}&tags=isms`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_ISOTWO_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            forms: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByAnnexNew = (annex) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?tech_sec=${annex}&tags=tech_sec`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_ISONEW_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            forms: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByContinuity = (continuity_iso) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?continuity_iso=${continuity_iso}&tags=continuity`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_CONTINUITY_ISO_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            forms: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormsBySubcriteria = (subcriteria_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?subcriteria=${subcriteria_id}&tags=blockchain`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_SUBCRITERIA_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFormsByStandardAndGroup = (tag, groupId) => async (dispatch) => {
    if (tag === 'pcidss') {
        dispatch(GetAllFormsBySubRequirement(groupId));
    } else if (tag === 'card4') {
        dispatch(GetAllPcidss4Point0FormsBySubRequirement(groupId));
    } else if (tag === 'ndpr') {
        dispatch(GetAllFormsByCategory(groupId));
    } else if (tag === 'iso27001') {
        dispatch(GetAllFormsByAnnex(groupId));
    } else if (tag === 'isms') {
        dispatch(GetAllFormsByAnnexTwo(groupId));
    } else if (tag === 'tech_sec') {
        dispatch(GetAllFormsByAnnexNew(groupId));
    } else if (tag === 'soc2') {
        dispatch(GetFormsBySubcontrol(groupId));
    } else if (tag === 'blockchain') {
        dispatch(GetAllFormsBySubcriteria(groupId));
    } else if (tag === 'continuity') {
        dispatch(GetAllFormsByContinuity(groupId));
    } else if (tag === 'cii') {
        dispatch(GetAllFormsBySubSector(groupId));
    } else if (tag === 'sslc') {
        dispatch(GetFormsByLifecycle(groupId));
    } else if (tag === 'iso9001') {
        dispatch(GetFormsByManagement(groupId));
    }
};
export const CreateForm = (credentials, groupId) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/forms/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFormsByStandardAndGroup(credentials?.tags, groupId));
        return {
            success: true,
            message: res?.message,
            form: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditForm = (id, credentials, tag, groupId) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/forms/${id}/ `, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFormsByStandardAndGroup(tag, groupId));
        return {
            success: true,
            message: res?.message,
            form: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteForm = (id, groupId, tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/forms/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFormsByStandardAndGroup(tag, groupId));
        return {
            success: true,
            message: 'Form deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllFormFields = (form_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/fields/?form=${form_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_FORM_FIELDS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateFormField = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/fields/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFormFields(credentials?.form));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditFormField = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/fields/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFormFields(credentials?.form));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteFormField = (id, form_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/fields/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFormFields(form_id));
        dispatch(GetAllFormsByContinuity(form_id));
        return {
            success: true,
            message: 'Deleted field from form successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// conditional items
export const GetAllConditionalItems = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/conditional/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_CONDITIONAL_ITEMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateCondtionalItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/conditional/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditCondtionalItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/conditional/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCondtionalItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/conditional/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllConditionalItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// tools
export const GetAllToolsItems = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/blockchaintool/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_TOOLS_ITEMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateToolsItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/blockchaintool/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditToolsItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/blockchaintool/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteToolsItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/blockchaintool/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetUserTools = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/userblockchaintool/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_BACKGROUND_CHECK_TOOLS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetUserCiiTools = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/usercii_tool/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_USER_CII_TOOLS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetUserToolsById = (blockchaintool) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    const payload = {
        blockchaintool,
        merchant: company_id,
    };
    try {
        const res = await axiosInstance().post(`/compliance/userblockchaintool/`, payload);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_USER_TOOLS_BY_ID,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// onboarding
export const GetAllOnboardingItems = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/onboarding/?admin=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ONBOARDING_ITEMS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateOnboardingItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/onboarding/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditOnboardingItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/onboarding/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteOnboardingItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/onboarding/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllOnboardingItems());
        return {
            success: true,
            message: 'Item deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Threats
export const GetAllThreats = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/threat/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_THREATS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddThreat = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/threat/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreats());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddBulkThreats = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/bulk_threat/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreats());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditThreat = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/threat/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreats());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteThreat = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/threat/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreats());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Threat Category
export const GetAllThreatCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/threat_categories/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_THREATS_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddThreatCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/threat_categories/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreatCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditThreatCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/threat_categories/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreatCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteThreatCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/threat_categories/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreatCategories());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Risk Category
export const GetAllRiskCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_RISK_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddRiskCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/risk_category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditRiskCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/risk_category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteRiskCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_category/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskCategories());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Vulnerabikites
export const GetAllVulnerabilities = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/vulnerabilities/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_VULNERABILITIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddVulnerability = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/vulnerabilities/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddBulkVulnerabilites = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/bulk_vulnerability/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditAddVulnerability = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/vulnerabilities/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteVulnerability = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/vulnerabilities/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilities());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Supports
export const GetAllSupportUsers = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/support_user/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SUPPORT_USERS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddSupportUser = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/support_user/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSupportUsers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditSupportUser = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/support_user/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSupportUsers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteSupportUser = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/users/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSupportUsers());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Releases
export const GetAllReleases = (page) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(page ? `/user_mgt/releases/?page=${page}` : `/user_mgt/releases/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_RELEASES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddRelease = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/releases/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllReleases());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditRelease = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/releases/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllReleases());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteRelease = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/releases/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllReleases());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//integrations
export const GetAllIntegrationsGroups = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/groups/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_INTEGRATIONS_GROUPS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddIntegrationGroup = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/integrations/groups/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditIntegrationGroup = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/integrations/groups/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteIntegrationGroup = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/integrations/groups/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllIntegrationsByGroup = (id, group_name) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance().get(`/integrations/groups/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        const integration_by_group = getState()?.adminReducers?.all_integrations_by_group;
        dispatch({
            type: ALL_INTEGRATIONS_BY_GROUP,
            payload: {
                ...integration_by_group,
                [group_name]: res?.data?.integrations,
            },
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetIntegrationByID = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/integration/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: INTEGRATION_BY_ID, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddIntegrations = (credentials, group_id, group_name) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/integrations/integration/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsByGroup(group_id, group_name));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditIntegration = (id, credentials, group_id, group_name) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/integrations/integration/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsByGroup(group_id, group_name));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditIntegrationFields = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/integrations/integration/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetIntegrationByID(id));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteIntegration = (id, group_id, group_name) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/integrations/integration/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsByGroup(group_id, group_name));
        return {
            success: true,
            message: 'Delete Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// F A Q S
export const GetAllFAQs = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/faqs/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_FAQS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddFAQItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/faqs/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFAQs());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateFAQItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/faqs/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFAQs());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteFAQItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/faqs/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFAQs());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// G L O S S A R Y
export const GetAllGlossaries = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/glossary/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_GLOSSARY,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddGlossaryItem = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/glossary/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGlossaries());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateGlossaryItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/glossary/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGlossaries());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteGlossaryItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/glossary/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllGlossaries());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// T E S T I M O N I A L S
export const GetAllTestimonials = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/landing_page/testimonial/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_TESTIMONIALS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddTestimonial = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/landing_page/testimonial/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTestimonials());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateTestimonial = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/landing_page/testimonial/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTestimonials());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteTestimonial = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/landing_page/testimonial/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTestimonials());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Conditional

export const GetConditionalById = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/conditional/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: CONDITIONAL_BY_ID,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//push notifications
export const GetAllPushNotifications = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/push_notification/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_PUSH_NOTIFICATIONS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SendPushNotification = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/push_notification/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPushNotifications());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditPushNotification = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/push_notification/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPushNotifications());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeletePushNotification = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/push_notification/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPushNotifications());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllUsers = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/users/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_USERS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetCodeReviewDocument = (codereview_id, merchant) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/codereview_result/?merchant=${merchant}&codereview_id=${codereview_id}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: CODEREVIEW_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UploadCodeReviewDocument = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/codereview_result/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCodeReview());
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllSurvey = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/survey_admin/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_SURVEY,
            payload: res?.data,
        });
        return {
            success: true,
            message: 'successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const CreateSurveyQuestion = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/survey_admin/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurvey());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllPentestRequests = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/pentest_request/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PENTESTS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditSurveyQuestion = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/survey_admin/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurvey());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteSrveyQuestion = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/survey_admin/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurvey());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SendMerchantSurvey = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/send_survey_emails/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurvey());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UploadPentestReport = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/asvc_requests/pentest_report/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPentestRequests());
        return {
            success: true,
            message: 'Report upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllSurveyResponses = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/survey_admin_summary/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SURVEY_RESPONSE, payload: res?.data });
        return {
            success: true,
            message: 'Response request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CheckSurveyCompletion = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/check_survey_completion/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurvey());
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateSupportUser = (credentials, id) => async () => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/employees/${id}/`, {
            ...credentials,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: 'Successfully updated a user info.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//users
export const GetAllEmployees = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/employees/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EMPLOYEES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

//Data room
export const GetAllAdminUploadedDocs = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/datadoc_upload/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ADMIN_UPLOADED_DOCS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

export const UploadAdminDocs = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/datadoc_upload/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminUploadedDocs());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateAdminDocs = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/datadoc_upload/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminUploadedDocs());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteAdminDocs = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/datadoc_upload/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminUploadedDocs());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantsExtraDocs = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/doc_upload_files/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_EXTRA_DOCS, payload: res?.data });
        return {
            success: true,
            message: 'Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
