import Loader2 from 'assets/img/loader-square (1).svg';
import Loader3 from 'assets/img/loader-square (2).svg';
import Loader1 from 'assets/img/loader-square.svg';
import RiskPolygon from 'assets/img/risk-polygon.png';
import Vifly from 'assets/img/vifly.svg';
import { useState } from 'react';

const MitogationControls = ({ mitigation_controls, setMitigationControls, error, recommendations, loadingAI }) => {
    const [show, setShow] = useState(false);
    return (
        <div className="relative group">
            <div className="w-full flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75">
                {mitigation_controls?.map((control, index) => (
                    <button
                        key={index}
                        className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6 w-fit"
                        onClick={() => {
                            setMitigationControls(mitigation_controls?.filter((item) => item !== control));
                        }}
                    >
                        <p className="font-normal capitalize text-[13px] text-[#64748B]">{control}</p>

                        <img
                            src="/img/risk-assessment/x-close-small.svg"
                            alt="Delete Icon"
                            className="object-contain"
                        />
                    </button>
                ))}
                <input
                    placeholder="Choose or add one or more controls"
                    onFocus={(event) => {
                        if (event.target.innerText === 'Choose or add one or more controls') {
                            event.target.innerText = '';
                        }

                        event.target.style.color = 'hsl(215,20%,40%)';
                        setShow(true);
                    }}
                    onChange={(event) => {
                        if (event?.target?.value?.includes(',,') && event.target.value.length > 2) {
                            setMitigationControls(
                                [...mitigation_controls, ...event.target.value.split(',,')].filter(Boolean)
                            );
                            event.target.value = '';
                        }
                    }}
                    className="border-none text-[#94A3B8] outline-none w-full"
                />
            </div>
            {error && mitigation_controls?.length === 0 && (
                <span className="text-xs text-[#FF0000] font-normal mt-2">Mitigation control is required</span>
            )}

            {show && (
                <div className="bg-[#FFFFFF] border border-[#F0F0FA] rounded-md shadow-2xl absolute top-[20%] -left-[368px] w-[350px] py-3">
                    <div className="flex justify-between items-center border-b border-[#F0F0FA] pb-2 px-3">
                        <h4 className="text-[#202D66] text-[15px] font-semibold">Suggested Controls</h4>
                        <div className="flex items-center gap-1">
                            <p className="tetx-[#64748B] text-xs font-normal">generated by</p>
                            <img src={Vifly} alt="Vifly" />
                        </div>
                    </div>
                    <div className="px-[1.5rem] h-[300px] overflow-auto">
                        {loadingAI ? (
                            <div className="flex justify-center items-center flex-col mt-8">
                                <img src={Loader1} alt="Loader1" className="mb-2" />
                                <img src={Loader2} alt="Loader2" className="mb-2" />
                                <img src={Loader3} alt="Loader3" className="mb-2" />
                            </div>
                        ) : (
                            <div className="mt-3">
                                {recommendations
                                    ?.filter((recommendation) => !mitigation_controls?.includes(recommendation))
                                    ?.map((recommendation, index) => (
                                        <p
                                            key={index}
                                            className="px-3 py-1.5 bg-[#F8FAFC] border border-[#F1F5F9] rounded mb-2 text-gray-500 text-[13px] font-normal cursor-pointer"
                                            onClick={() => {
                                                setMitigationControls(
                                                    [...mitigation_controls, recommendation]?.filter(Boolean)
                                                );
                                            }}
                                        >
                                            {recommendation}
                                        </p>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {show && (
                <div className="absolute bottom-2 -left-[15px] hidden group-focus-within:block">
                    <img src={RiskPolygon} alt="RiskPolygon" />
                </div>
            )}
        </div>
    );
};
export default MitogationControls;
