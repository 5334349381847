import { MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import HistoryIcon from '@mui/icons-material/History';
import Download from 'assets/img/download01.svg';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { connect, useDispatch } from 'react-redux';
import { ExemptMerchantDocument } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { GetDocumentLink } from 'store/actions/complianceActions';
import LoadingState from 'components/new_components/LoadingState';

const PolicyDocument = (props) => {
    const {
        control,
        setDoc_id,
        openHistoryModal,
        openUploadModal,
        all_merchant_documents,
        standard,
        ExemptMerchantDocument,
        getControlDocuments,
        subcontrolId,
    } = props;
    const [anchor, setAnchor] = useState(null);
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const [loadingLink, setLoadingLink] = useState(false);
    const dispatch = useDispatch();

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === control?.id);
    }, [control, all_merchant_documents]);

    const exemptMerchantDocument = async () => {
        const payload = {
            document: control?.id,
            standard,
        };
        setLoading({ ...loading, exempt: true });
        const res = await ExemptMerchantDocument(payload);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            getControlDocuments(subcontrolId);
        } else {
            toast.error(res?.message);
        }
    };

    const getDocumentLink = async () => {
        setLoadingLink(true);
        const res = await dispatch(GetDocumentLink('document', control?.id));
        setLoadingLink(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <Box key={control?.id}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '60%',
                        }}
                    >
                        <img src={FileUnCheck} alt="fileunckeck" />{' '}
                        <Typography
                            sx={{
                                ml: 2,
                                color: '#395BA9',
                                fontSize: '13px',
                                fontWeight: 500,
                            }}
                        >
                            {control?.soc2_title || control?.lifecycle_title || control?.quality_mgt_title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {selectedDocument?.approved_status === 'pending' && (
                            <Tooltip
                                title="Pending approval"
                                arrow
                                placement="bottom-end"
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            color: '#FFFFFF',
                                            backgroundColor: '#000000',
                                        },
                                    },
                                }}
                            >
                                <ErrorOutlineIcon sx={{ color: '#B28C00' }} />
                            </Tooltip>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                sx={{
                                    textTransform: 'inherit',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    color: selectedDocument ? '#37A372' : '#395BA9',
                                    border: '1px solid #E2E8F0',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                onClick={() => {
                                    if (!selectedDocument) {
                                        setDoc_id(control?.id);
                                        openUploadModal();
                                    }
                                }}
                                variant="outlined"
                                startIcon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            color: selectedDocument ? '#37A372' : '#395BA9',
                                        }}
                                    />
                                }
                            >
                                {selectedDocument ? 'OK' : 'Upload policy'}
                            </Button>
                            <>
                                <Box sx={{ ml: 2, cursor: 'pointer' }} onClick={openMenu}>
                                    <MoreVert />
                                </Box>
                                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                                    <MenuItem
                                        disabled={selectedDocument?.document_id !== control?.id}
                                        onClick={() => {
                                            setDoc_id(control?.id);
                                            openHistoryModal();
                                            closeMenu();
                                        }}
                                    >
                                        <HistoryIcon sx={{ color: '#64748B' }} />

                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                color: '#77777A',
                                                ml: 0.5,
                                            }}
                                        >
                                            {' '}
                                            Policy History
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={getDocumentLink}
                                        >
                                            <img src={Download} alt="Download" />{' '}
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#77777A',
                                                    ml: 0.5,
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {loadingLink ? <LoadingState size={20} /> : 'Download template'}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={exemptMerchantDocument}>
                                        <DoDisturbAltIcon sx={{ color: 'red' }} />{' '}
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                color: 'red',
                                                ml: 0.5,
                                            }}
                                        >
                                            {loading.exempt ? 'Exempting...' : 'Not applicable'}
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ color: '#F1F5F9', mx: '-2rem' }} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};
export default connect(mapStateToProps, { ExemptMerchantDocument })(PolicyDocument);
