import { Stack, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import {
    ArcElement,
    Chart as ChartJS,
    Legend,
    Tooltip,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js';
import { ReduceValues } from './utils';
import { useSelector } from 'react-redux';
import { Fragment, useMemo } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);
ChartJS.defaults.plugins.tooltip.backgroundColor = '#395BA9';
ChartJS.defaults.plugins.tooltip.titleColor = '#fff';
ChartJS.defaults.plugins.tooltip.titleAlign = 'center';
ChartJS.defaults.plugins.tooltip.bodyAlign = 'center';
ChartJS.defaults.plugins.tooltip.displayColors = false;
ChartJS.defaults.plugins.tooltip.titleFont = {
    family: 'Poppins, sans-serif',
    weight: 'bold',
};
ChartJS.defaults.plugins.tooltip.bodyFont = {
    family: 'Poppins, sans-serif',
    weight: 'normal',
};
ChartJS.defaults.plugins.tooltip.boxPadding = 4;
ChartJS.defaults.plugins.tooltip.xAlign = 'center';
ChartJS.defaults.plugins.tooltip.yAlign = 'bottom';

const zeroDataBackgroundPlugin = {
    id: 'zeroDataBackground',
    beforeDraw: (chart) => {
        const { datasets } = chart.data;
        const ctx = chart.ctx;

        // Check if datasets exist and contain data
        if (!datasets || !datasets.length || !datasets[0].data.length) {
            return; // Exit early if no data
        }

        // Check if all data values are zero
        const allDataZero = datasets[0].data.every((value) => value === 0);

        if (allDataZero) {
            const { chartArea } = chart;
            const centerX = (chartArea.left + chartArea.right) / 2;
            const centerY = (chartArea.top + chartArea.bottom) / 2;

            // Ensure the dataset meta is available and has data
            const meta = chart.getDatasetMeta(0);
            if (!meta || !meta.data || !meta.data.length) {
                return; // Exit if meta data is not available
            }

            const { outerRadius, innerRadius } = meta.data[0].getProps(['outerRadius', 'innerRadius'], true);

            ctx.save();
            ctx.fillStyle = '#f0f0f0'; // Set your desired background color here
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI); // Outer circle
            ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI, true); // Inner circle (inverted)

            ctx.fill();
            ctx.restore();

            // Optional: Add a message on the chart
            ctx.font = '20px Poppins';
            ctx.fillStyle = '#666';
            ctx.textAlign = 'center';
            // ctx.fillText('No Data Available', centerX, centerY);
        }
    },
};

ChartJS.register(zeroDataBackgroundPlugin);

export default function RiskCategory() {
    // constants
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);
    const sortByDescending = (a, b) => b.count - a.count;
    const MAX_ON_CHART = 3;
    const Blanks = (num) => Array(num).fill(0);

    const categories = useMemo(() => {
        return overview?.risk_category?.sort(sortByDescending).map((el) => el.category__name) ?? [];
    }, [overview]);

    const values = useMemo(() => {
        return overview
            ? overview?.risk_category?.sort(sortByDescending).map((el) => el.count) ?? Blanks(4)
            : Blanks(4);
    }, [overview]);

    let colors = ['#395BA9', '#B1C5FF', '#FEBD38', '#68EE76'];

    const data = useMemo(
        () => ({
            labels: [...categories.slice(0, MAX_ON_CHART), 'Others'],
            datasets: [
                {
                    data: [...values.slice(0, MAX_ON_CHART), ReduceValues(values.slice(MAX_ON_CHART))],
                    backgroundColor: [colors[0], colors[1], colors[2], colors[3]],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [overview]
    );

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            zeroDataBackground: {}, // Enable the plugin
            tooltip: {
                font: {
                    weight: 'bold',
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Risk category
                </Typography>
            </Stack>
            <div className="w-full flex flex-col justify-start items-start gap-10">
                <div className="flex gap-4 justify-center items-center my-6 mx-auto">
                    <div className="w-[200px]">
                        <Doughnut data={data} options={options} width={250} />
                    </div>

                    <div className="flex flex-col gap-3">
                        {[
                            ...categories.slice(0, MAX_ON_CHART),
                            ...(categories.length > MAX_ON_CHART ? ['Others'] : []),
                        ].map((value, index) => {
                            return (
                                <div key={`service-${index}`} className="flex justify-between gap-6 items-center">
                                    <div className="flex justify-between gap-2 items-center">
                                        <div
                                            className="w-3 h-3 rounded-full"
                                            style={{ backgroundColor: colors[index] }}
                                        />
                                        <p className="text-gray-600 text-xs">{value}</p>
                                    </div>

                                    <div className="px-1 py-0 rounded-md bg-[#94A3B8] text-[#FAFAFA] text-[11px] h-fit">
                                        {index < MAX_ON_CHART
                                            ? values[index]
                                            : ReduceValues(values.slice(MAX_ON_CHART))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {categories.length > MAX_ON_CHART ? (
                    <div className="flex gap-1 flex-wrap">
                        <p className="text-gray-400 text-xs">Others: </p>
                        {categories?.slice(MAX_ON_CHART)?.map((value, index) => {
                            return (
                                <Fragment key={index}>
                                    {value?.[1] ? (
                                        <p className="text-gray-500 text-xs">
                                            {value +
                                                `(${values.slice(MAX_ON_CHART)[index]})` +
                                                `${index < values.slice(MAX_ON_CHART).length - 1 ? ',' : ''}`}
                                        </p>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    );
}
