import React, { useEffect } from 'react';
import logo from 'assets/img/vendor_smart_logo.svg';
import Selector from 'assets/img/chevron-selector-vertical.svg';
import Logout from 'assets/img/solar_logout-linear.svg';
import Bell from 'assets/img/Bell.svg';
import { MenuItem, Popover } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { RiskSelfAccess } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import { riskPermission } from './utils/constants';
import { RiskcurrentPermission } from 'store/actions/generalActions';
import { useHistory } from 'react-router-dom';
import { RISK_SELF_PORTAL_TOKEN } from 'store/constants';

const Header = ({ id, open, openSwitch, hideSwitch, showSwitch, setCurrentTab }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { risk_permission, risk_current_permission, risk_self_portal_token } = useSelector(
        (state) => state?.generalReducers
    );

    const handlePermission = (permission) => {
        dispatch(RiskcurrentPermission(permission?.value));
    };

    const handleLogout = () => {
        dispatch({ type: RISK_SELF_PORTAL_TOKEN, payload: undefined });
        localStorage.clear();
        history.push('/risk-services/login/');
    };

    // Async function
    const getRiskSelfAssess = async () => {
        const res = await dispatch(RiskSelfAccess());
        if (!res?.success) {
            toast.error(res?.message || "Something went wrong, couldn't log you in");
        }
    };
    useEffect(() => {
        if (risk_permission && !risk_current_permission) {
            dispatch(
                RiskcurrentPermission(
                    risk_permission?.risk_migation
                        ? 'risk_migation'
                        : risk_permission?.escalator
                        ? 'escalator'
                        : risk_permission?.approval
                        ? 'approval'
                        : null
                )
            );
        }
    }, [risk_permission, risk_current_permission]);

    useEffect(() => {
        if (!risk_permission?.risk_migation && !risk_permission?.approval && !risk_permission?.escalator) {
            getRiskSelfAssess();
        }
    }, [risk_permission]);

    useEffect(() => {
        if (!risk_self_portal_token) {
            history.push('/risk-services/login/');
        }
    }, [risk_self_portal_token]);

    return (
        <div className="flex justify-between items-center py-2 px-6">
            <div>
                <p className="text-[#002C72] text-lg font-bold">Risk Register</p>
                <div
                    className="cursor-pointer flex items-center gap-1 mt-1 ml-[-0.2rem]"
                    aria-describedby={id}
                    onClick={showSwitch}
                >
                    <img src={Selector} alt="Selector" />
                    <p className="text-[#002C72] text-sm font-medium">
                        {risk_current_permission === 'risk_migation'
                            ? 'Risk Mitigation'
                            : risk_current_permission === 'escalator'
                            ? 'Risk Escalator'
                            : 'Management Approval'}
                    </p>
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={openSwitch}
                    onClose={hideSwitch}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            minWidth: 220,
                            minHeight: 100,
                            maxHeight: 400,
                            overflow: 'scroll',
                        },
                    }}
                >
                    {riskPermission?.map((item, index) => {
                        return !risk_permission?.approval &&
                            item?.value === 'approval' ? null : !risk_permission?.escalator &&
                          item?.value === 'escalator' ? null : !risk_permission?.risk_migation &&
                          item?.value === 'risk_migation' ? null : (
                            <div key={item?.value}>
                                <MenuItem
                                    sx={{
                                        color: '#475569',
                                        fontWeight: 'medium',
                                        fontSize: '14px',
                                        padding: '8px 16px',
                                        display: 'flex',
                                        gap: 3,
                                        borderBottom: `1px solid ${
                                            index === riskPermission.length - 1 ? 'transparent' : '#F1F5F9'
                                        }`,
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: '#F1F5F9',
                                        },
                                    }}
                                    onClick={() => {
                                        handlePermission(item);
                                        setCurrentTab('all');
                                        hideSwitch();
                                    }}
                                >
                                    <p>{item?.name}</p>{' '}
                                    {risk_current_permission === item?.value && (
                                        <CheckIcon sx={{ color: '#395BA9', width: 18, height: 18 }} />
                                    )}
                                </MenuItem>
                            </div>
                        );
                    })}
                </Popover>
            </div>
            <div className="flex items-center gap-3">
                <img src={logo} alt="SmartComply logo" />
                <div className="flex items-center gap-3 bg-[#F1F5F9] rounded-full px-3 py-2">
                    <img src={Bell} alt="Notification Icon" />
                    <div onClick={handleLogout} className="cursor-pointer">
                        <img src={Logout} alt="Logout" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
