import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiHelpCircle } from 'react-icons/fi';
import { Box, Button, Typography } from '@mui/material';
// import { Edit, Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import AppButton from 'components/new_components/AppButton';
import {
    AppForm,
    AppFormInput,
    AppFormSelect,
    // AppSubmitButton
} from 'components/new_components/forms';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

//validation
import { AMLVendorsValidation, vendorsValidation } from '../../utils/validation';
import { riskLevelOptions } from '../../utils/constants';

//translations
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import { AppSubmitButton } from 'components/new_components/forms';
// import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import AMLSettingsModal from './AMLSettingsModal';
import AppFormCheckbox from 'components/new_components/forms/AppFormCheckbox';
import { AppFormContext } from 'components/new_components/forms';
import { AddInAppSearch } from 'store/actions/vendorActions';

const VendorModal = (props) => {
    const { open, handleClose, isAddModal, id, payload, openRiskLevelsModal } = props;

    const [loadingState, setLoadingState] = useState(false);
    const [openAMLSettings, setOpenAMLSettings] = useState(false);
    const [type, setType] = useState('AML');
    const [values, setValues] = useState(payload);
    const [sources, setSources] = useState([]);
    const [continous_monitoring, setcontinous_monitoring] = useState(false);
    const dispatch = useDispatch();
    const all_vendors = useSelector((state) => state?.vendorReducers?.all_vendors);

    const theme = useTheme();

    const handleOpenAML = () => {
        setOpenAMLSettings(true);
    };

    const handleCloseAML = () => {
        setOpenAMLSettings(false);
    };

    //translation
    const { t } = useTranslation('vendorManagement');

    const addInAppSearch = async () => {
        const body = {
            entity_name: values.name,
            sources,
            continous_monitoring,
        };
        const res = await dispatch(AddInAppSearch(body));
        if (res?.success) {
            setLoadingState(false);
            toast.success(isAddModal ? 'Vendor Added Successfully' : 'Vendor Credentials Updated Successfully');
            handleClose();
        } else {
            setLoadingState(false);
            toast.error(res?.message);
        }
    };

    const handleSubmit = async (values) => {
        setLoadingState(true);
        const non_amlValues = {
            name: values.name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            email: values.email,
            is_aml_vendor: false,
        };

        const amlValues = {
            name: values.name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            email: values.email,
            min_risk_level: values.min_risk_level,
            is_aml_vendor: true,
            ran_aml: values?.ran_aml,
        };
        const body = type === 'AML' ? amlValues : non_amlValues;
        const res = isAddModal ? await dispatch(AddVendor(body)) : await dispatch(EditVendor(body, id));
        if (res?.success) {
            if (values?.ran_aml) {
                addInAppSearch();
            } else {
                setLoadingState(false);
                toast.success(isAddModal ? 'Vendor Added Successfully' : 'Vendor Credentials Updated Successfully');
                handleClose();
            }
        } else {
            setLoadingState(false);
            toast.error(res?.message);
        }
    };
    const getCurrentValues = (values) => setValues(values);
    useEffect(() => {
        if (payload) {
            setType(payload.is_aml_vendor ? 'AML' : 'NON-AML');
        }
    }, [payload]);
    const filtered_vendors = all_vendors?.filter((vendor) => vendor?.is_aml_vendor === true);
    return (
        <AppForm
            initialValues={payload}
            onSubmit={handleSubmit}
            validate={type === 'AML' ? AMLVendorsValidation : vendorsValidation}
        >
            <AppFormContext getValues={getCurrentValues}>
                <AppDrawer
                    open={open}
                    handleClose={handleClose}
                    title={isAddModal ? t('vendorModal.addVendor') : t('vendorModal.editVendor')}
                    icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                    noShadow
                    noClose
                    headerAction={
                        <>
                            <AppButton
                                name={t('vendorModal.sensitivity')}
                                sx={{
                                    fontSize: '12px',
                                    fontStyle: 'italic',
                                    fontWeight: '400',
                                    color: theme.palette.primary[200],
                                    '&:hover': {
                                        background: 'transparent !important',
                                    },
                                }}
                                icon={<FiHelpCircle size="13px" />}
                                onClick={openRiskLevelsModal}
                            />
                        </>
                    }
                    sx={{ overflow: 'hidden' }}
                    width="550px"
                    // height="520px"
                >
                    <Box
                        sx={{
                            mx: 3,
                            my: 2,
                            overflow: 'scroll !important',
                            height: type === 'AML' ? '75vh' : '70vh',
                            [theme.breakpoints.down('lg')]: {
                                height: type === 'AML' ? '64vh' : '68vh',
                            },
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: '600' }}>
                            Vendor questionnaire type
                        </Typography>
                        <Box
                            sx={{
                                border: '2px solid #395BA9',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    backgroundColor: type === 'AML' ? '#395BA9' : 'transpparent',
                                    width: '50%',
                                    color: type === 'AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setType('AML')}
                            >
                                AML vendor
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor: type === 'NON-AML' ? '#395BA9' : 'transpparent',
                                    width: '50%',
                                    color: type === 'NON-AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setType('NON-AML')}
                            >
                                NON-AML vendor
                            </Typography>
                        </Box>
                        {type === 'AML' && (
                            <Box>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#64748B', my: 1 }}>
                                    For vendors subjected to anti-money laundering regulations{' '}
                                </Typography>
                                <Box>
                                    <AppFormInput type="text" name="name" placeholder={t('vendorModal.vendorName')} />
                                    <AppFormInput
                                        type="text"
                                        name="email"
                                        placeholder={t('vendorModal.emailAddress')}
                                        disabled={!isAddModal}
                                    />
                                    <AppFormInput type="text" name="data" placeholder={t('vendorModal.dataShared')} />
                                    <AppFormInput type="text" name="products" placeholder={'products'} />
                                    <AppFormSelect
                                        name="risk_level"
                                        options={riskLevelOptions}
                                        defaultValue="Risk level"
                                        sx={{ height: '40px' }}
                                    />
                                    <AppFormInput
                                        type="number"
                                        name="min_risk_level"
                                        placeholder="Vendor minimum risk alert level"
                                    />
                                    <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '12px', mt: 1 }}>
                                        Get alerted when a vendor scorecard is within the minimum risk alert level.
                                    </Typography>
                                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '12px', mt: 0.5 }}>
                                        {' '}
                                        Note: The lower the score, the more the risk. Highest score is 100
                                    </Typography>
                                    <AppFormInput
                                        type="text"
                                        name="description"
                                        placeholder={'Description of service'}
                                        multiline={true}
                                        rows={3}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mt: '-1rem',
                                        }}
                                    >
                                        <Box>
                                            <AppFormCheckbox
                                                name="ran_aml"
                                                // value={run_aml}
                                                // onChange={(event) => setRun_aml(event.target.checked)}
                                                label={
                                                    <Typography
                                                        sx={{ color: '#395BA9', fontSize: '13px', fontWeight: 500 }}
                                                    >
                                                        {' '}
                                                        Run AML check on this vendor
                                                    </Typography>
                                                }
                                            />
                                            <Typography sx={{ fontSize: '11px', mt: '-0.5rem' }}>
                                                You have{' '}
                                                <span style={{ fontWeight: 500, color: '#395BA9' }}>
                                                    {filtered_vendors?.length} vendors
                                                </span>{' '}
                                                to run AML check on
                                            </Typography>
                                        </Box>
                                        {values?.ran_aml && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleOpenAML}
                                            >
                                                <SettingsOutlinedIcon />
                                                <Typography
                                                    sx={{
                                                        color: '#475569',
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    Settings
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {type === 'NON-AML' && (
                            <Box>
                                <AppFormInput type="text" name="name" placeholder={t('vendorModal.vendorName')} />
                                <AppFormInput
                                    type="text"
                                    name="email"
                                    placeholder={t('vendorModal.emailAddress')}
                                    disabled={!isAddModal}
                                />
                                <AppFormInput type="text" name="data" placeholder={t('vendorModal.dataShared')} />
                                <AppFormInput type="text" name="products" placeholder={t('vendorModal.product')} />
                                <AppFormSelect
                                    name="risk_level"
                                    options={riskLevelOptions}
                                    defaultValue={t('vendorModal.riskLevelValue')}
                                />
                                <AppFormInput
                                    type="number"
                                    name="min_risk_level"
                                    placeholder="Vendor minimum risk alert level"
                                />
                                <AppFormInput
                                    type="text"
                                    name="description"
                                    placeholder={'Description of service'}
                                    multiline={true}
                                    rows={3}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            paddingLeft: '23rem',
                            backgroundColor: '#F8FAFC',
                            width: '100% !important',
                            py: 2.5,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <AppSubmitButton
                            text="ADD"
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                    <AMLSettingsModal
                        open={openAMLSettings}
                        handleClose={handleCloseAML}
                        values={values}
                        sources={sources}
                        setSources={setSources}
                        continous_monitoring={continous_monitoring}
                        setcontinous_monitoring={setcontinous_monitoring}
                    />
                </AppDrawer>
            </AppFormContext>
        </AppForm>
    );
};

export default VendorModal;
