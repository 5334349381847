import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Input } from 'antd';
import { BsDownload } from 'react-icons/bs';
import { IconButton } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
//redux
import { connect } from 'react-redux';
import { GetAllMerchantsExtraDocs } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import useSearch from 'hooks/useSearch';

const ExtraDocs = (props) => {
    const [loading, setLoading] = useState(false);
    const { GetAllMerchantsExtraDocs, merchants_extra_docs } = props;
    // hooks
    const { data, handleSearch } = useSearch(merchants_extra_docs, ['merchant_name', 'company_name', 'name']);
    const columns = [
        {
            title: 'Doc Name',
            render: (record) => {
                return record?.name;
            },
        },
        {
            title: 'Merchant',
            render: (record) => {
                return record?.company_name ? record?.company_name : record?.merchant_name;
            },
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <IconButton
                    aria-label="history"
                    component="a"
                    href={record?.doc_file}
                    target="_blank"
                    rel="noreferrer"
                    download
                >
                    <BsDownload color="#77777A" size="18px" />
                </IconButton>
            ),
        },
    ];

    useEffect(() => {
        const getAllMerchantsExtraDocs = async () => {
            setLoading(true);
            const res = await GetAllMerchantsExtraDocs();
            setLoading(false);

            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantsExtraDocs();
    }, []);
    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(data) => data?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        merchants_extra_docs: state?.adminReducers?.merchants_extra_docs,
    };
};
export default connect(mapStateToProps, { GetAllMerchantsExtraDocs })(ExtraDocs);
