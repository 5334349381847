import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';

const ComplianceMenu = (props) => {
    const { anchor, closeMenu, items, hasResource, card4 } = props;
    const theme = useTheme();
    return (
        <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={closeMenu}
            onClick={closeMenu}
            elevation={0}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
                sx: {
                    boxShadow:
                        '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                    borderRadius: 2.5,
                },
            }}
        >
            {items?.map((item, index) => {
                const { label, action, sx, ...otherProps } = item;
                return (
                    <MenuItem
                        sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: theme.palette.gray[700],
                            display: 'flex',
                            justifyContent: 'center',
                            ...sx,
                        }}
                        disabled={card4 && label === 'Documents & Versions' && !hasResource}
                        onClick={action}
                        key={index}
                        {...otherProps}
                    >
                        {label}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

export default ComplianceMenu;
