import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { moreSteps } from './steps';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { connect } from 'react-redux';

// core component
import DoneStatusChip from './DoneStatusChip';
import MoreIcon from './MoreIcon';
import ComplianceMenu from './ComplianceMenu';
import ExemptTag from './ExemptTag';
import ExceptionModal from './ExemptionModal';
import ExemptionReason from './ExemptionReason';

//translation
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@mui/icons-material';
import DoneStatusChipNew from './DoneStatusChipNew';
import { getType } from 'utils';
import ExemptModalNew from './ExemptModalNew';

const ItemSubheader = (props) => {
    const {
        hasResource,
        exempting,
        onExempt,
        menuItems,
        resource,
        exception,
        blockchain,
        payload,
        onResourceChange,
        exceptionReason,
        userguide_info,
        standard,
        cii,
        no_excemption,
        is_tool,
        assigned,
    } = props;

    // hooks
    const theme = useTheme();

    // state
    const [menuAnchor, setMenuAnchor] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);
    const [openExempt, setOpenExempt] = useState(false);
    const [ModalReasonOpen, setModalReasonOpen] = useState(false);

    const [openUserGuide, setOpenUserGuide] = useState(false);

    const stepsTargetRef = useRef([]);
    const role = getType('role');

    const { updateOneUserGuide } = useContext(UserGuideContext);

    //translation
    const { t } = useTranslation('compliance');

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ sub_requirements: true });
        setOpenUserGuide(false);
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : null;
    }, [userguide_info]);

    // logic functions
    const openMenu = (e) => setMenuAnchor(e.currentTarget);
    const closeMenu = () => setMenuAnchor(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const handleExempt = () => {
        if (blockchain || standard === 'cii') {
            openModal();
        } else {
            onExempt();
        }
    };

    const openReasonModal = () => {
        setModalReasonOpen(true);
    };

    const closeReasonModal = () => {
        setModalReasonOpen(false);
    };
    // constants
    const action = resource === 'doc' ? t('uploaded') : t('answered');
    return (
        <Stack direction="row" justifyContent={exception ? 'flex-end' : 'space-between'} alignItems="flex-start">
            <Stack alignItems="flex-start">
                {!exception && (
                    <>
                        {standard === 'soc2' || standard === 'sslc' ? (
                            <DoneStatusChipNew
                                label={hasResource ? `${t('already')} ${action}` : `${t('not')} ${action}`}
                                done={hasResource}
                            />
                        ) : (
                            <DoneStatusChip
                                label={hasResource ? `${t('already')} ${action}` : `${t('not')} ${action}`}
                                done={hasResource}
                            />
                        )}
                    </>
                )}
                {standard !== 'blockchain' ||
                    (hasResource && (
                        <Typography
                            sx={{
                                pt: 1,
                                color: theme.palette.gray[700],
                                fontWeight: 500,
                                fontSize: 12,
                            }}
                        >
                            {t('clickOn')} <MoreIcon /> {t('icon')}{' '}
                            {resource === 'doc' ? t('uploadedDoc') : t('answeredQuestion')}
                        </Typography>
                    ))}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
                {exception ? (
                    <Typography
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={openReasonModal}
                    >
                        {t('viewReason')}
                    </Typography>
                ) : standard !== 'iso22301' && !no_excemption ? (
                    <>
                        {role !== 'auditor' && (
                            <>
                                {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' ? (
                                    <>
                                        <Tooltip title="Not Applicable">
                                            <Typography
                                                onClick={() => setOpenExempt(true)}
                                                ref={(el) => (stepsTargetRef.current[0] = el)}
                                                variant="outlined"
                                                sx={{
                                                    color: '#D2A500',
                                                    fontWeight: 700,
                                                    fontSize: '12px',
                                                    border: '1px solid #CBD5E1',
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                N/A
                                            </Typography>
                                        </Tooltip>
                                        {assigned && (
                                            <Typography
                                                sx={{
                                                    color: theme.palette.white.main,
                                                    background: theme.palette.tertiary[70],
                                                    fontSize: 9,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    p: 0.5,
                                                    fontWeight: 500,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                Assigned to you
                                            </Typography>
                                        )}
                                    </>
                                ) : (
                                    <ExemptTag
                                        onClick={handleExempt}
                                        loading={exempting}
                                        loadingLabel={t('exempting')}
                                        label={t('notApplicable')}
                                        ref={(el) => (stepsTargetRef.current[0] = el)}
                                    />
                                )}
                            </>
                        )}
                    </>
                ) : null}

                {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' ? (
                    <>
                        <Box
                            sx={{
                                border: '1px solid #E1E2EC',
                                borderRadius: 0.5,
                                ml: 2,
                                backgroundColor: '#F1F5F9',
                                cursor: 'pointer',
                            }}
                            onClick={openMenu}
                        >
                            <MoreHoriz />
                        </Box>
                        <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={closeMenu}>
                            {menuItems?.map((item, index) => (
                                <Box key={index}>
                                    <MenuItem
                                        sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B' }}
                                        onClick={() => {
                                            item.action();
                                            closeMenu();
                                        }}
                                        disabled={
                                            (!hasResource && item?.label === 'View policy history') ||
                                            (!hasResource && item?.active)
                                        }
                                    >
                                        {item.label}
                                    </MenuItem>
                                    {index === 0 && <Divider />}
                                </Box>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <>
                        {hasResource && !cii && !is_tool && standard !== 'card4' ? (
                            <>
                                {role !== 'auditor' && (
                                    <IconButton sx={{ p: 0 }} onClick={openMenu}>
                                        <MoreIcon size={30} ref={(el) => (stepsTargetRef.current[1] = el)} />
                                    </IconButton>
                                )}
                                <ComplianceMenu anchor={menuAnchor} closeMenu={closeMenu} items={menuItems} />
                            </>
                        ) : standard === 'card4' ? (
                            <>
                                {role !== 'auditor' && (
                                    <IconButton sx={{ p: 0 }} onClick={openMenu}>
                                        <MoreIcon size={30} ref={(el) => (stepsTargetRef.current[1] = el)} />
                                    </IconButton>
                                )}
                                <ComplianceMenu
                                    anchor={menuAnchor}
                                    closeMenu={closeMenu}
                                    items={menuItems}
                                    hasResource={hasResource}
                                    cii={cii}
                                    is_tool={is_tool}
                                    card4
                                />
                            </>
                        ) : null}
                    </>
                )}
            </Stack>
            <ExceptionModal
                open={ModalOpen}
                handleClose={closeModal}
                payload={payload}
                standard={standard}
                onResourceChange={onResourceChange}
            />
            <ExemptionReason open={ModalReasonOpen} handleClose={closeReasonModal} exceptionReason={exceptionReason} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={moreSteps}
                targets={stepsTargetRef.current}
            />
            <ExemptModalNew
                open={openExempt}
                handleClose={() => setOpenExempt(false)}
                handleExempt={handleExempt}
                loading={exempting}
                resource={resource}
            />
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.sub_requirements,
    };
};

export default connect(mapStateToProps)(ItemSubheader);
