import { Divider, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { RiskTemplatesTab, residual3x3Risks, residual5x5Risks, riskTypeOptions } from '../utils/constants';
import DownloadIcon from 'assets/img/download-risk.svg';
import ArrowDownIcon from 'assets/img/material-symbols_keyboard-arrow-down-rounded.svg';
import { useSelector } from 'react-redux';

const TopContent = (props) => {
    const {
        setCurrentTab,
        riskTypeAnchorEl,
        riskType,
        riskTypeChange,
        spoolCloseMenu,
        spoolOpenMenu,
        currentTab,
        riskTypeCloseMenu,
        riskTypeOpenMenu,
        spoolAnchorEl,
        setResidualRisk,
    } = props;
    const [maatrixAnchorEl, setMatrixAnchorEl] = useState(null);

    const { risk_self_portal_token } = useSelector((state) => state?.generalReducers);
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2  grid place-items-center ${
                        currentTab === 'all' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => setCurrentTab('all')}
                >
                    All
                </button>
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-[42px] h-8 grid place-items-center ${
                        currentTab === RiskTemplatesTab.FiveByFive
                            ? 'bg-[#395BA9] text-[#fff]'
                            : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => {
                        setCurrentTab(RiskTemplatesTab.FiveByFive);
                        setResidualRisk([...residual5x5Risks]);
                    }}
                >
                    5x5
                </button>

                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-[42px] h-8 grid place-items-center ${
                        currentTab === RiskTemplatesTab.ThreeByThree
                            ? 'bg-[#395BA9] text-[#fff]'
                            : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => {
                        setCurrentTab(RiskTemplatesTab.ThreeByThree);
                        setResidualRisk([...residual3x3Risks]);
                    }}
                >
                    3x3
                </button>
            </div>
            <div className="flex items-center gap-3">
                <div>
                    <div
                        className="bg-[#F1F5F9] border border-[#E2E8F0] flex items-center gap-1 rounded p-1.5 cursor-pointer"
                        onClick={spoolOpenMenu}
                    >
                        <img src={DownloadIcon} alt="Download Icon" />
                        <p className="text-[#395BA9] text-[13px] font-medium">Spool</p>
                    </div>
                    <Menu open={Boolean(spoolAnchorEl)} anchorEl={spoolAnchorEl} onClose={spoolCloseMenu}>
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '15px', fontWeight: 500, py: 0, pl: -5 }}
                            onClick={() => {
                                spoolCloseMenu();
                            }}
                        >
                            {' '}
                            <a
                                href={`https://api.smartcomplyapp.com/api/scans/cbn_self_service?token=${risk_self_portal_token}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                CBN report
                            </a>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '15px', fontWeight: 500, py: 0, pl: -5 }}
                            onClick={(event) => {
                                setMatrixAnchorEl(event.currentTarget);
                            }}
                        >
                            Smartcomply report
                        </MenuItem>
                    </Menu>
                </div>{' '}
                <Menu
                    open={Boolean(maatrixAnchorEl)}
                    anchorEl={maatrixAnchorEl}
                    onClose={() => setMatrixAnchorEl(null)}
                >
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://api.smartcomplyapp.com/api/scans/riskreport_self_service?token=${risk_self_portal_token}&matrix=3x3`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            3X3 Report
                        </a>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontSize: '15px',
                            fontWeight: 500,
                            py: 0,
                            pl: -5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <a
                            href={`https://api.smartcomplyapp.com/api/scans/riskreport_self_service?token=${risk_self_portal_token}&matrix=5x5`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            5X5 Report
                        </a>
                    </MenuItem>
                </Menu>
                <div>
                    <div
                        className="p-2 border border-[#E2E8F0] flex items-center gap-1 rounded cursor-pointer"
                        onClick={riskTypeOpenMenu}
                    >
                        <p className={`text-[13px] font-medium rounded-[4px]`}>{riskType?.name}</p>
                        <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                    </div>
                    <Menu open={Boolean(riskTypeAnchorEl)} anchorEl={riskTypeAnchorEl} onClose={riskTypeCloseMenu}>
                        {riskTypeOptions?.map((option, index) => (
                            <div key={index} className="p-[-2rem]">
                                <MenuItem
                                    sx={{
                                        color: '#64748B',
                                        fontSize: '15px',
                                        fontWeight: 500,
                                        padding: '8px 16px',
                                        borderBottom: `1px solid ${
                                            index === riskTypeOptions.length - 1 ? 'transparent' : '#F1F5F9'
                                        }`,
                                    }}
                                    onClick={() => {
                                        riskTypeChange(option);
                                        riskTypeCloseMenu();
                                    }}
                                >
                                    {option?.name}
                                </MenuItem>
                            </div>
                        ))}
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default TopContent;
