import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { CreateLifeCycle, EditLifeCycle } from 'store/actions/pci_slcActions';

const ObjectiveModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleCancel, one_control } = props;

    // redux
    const dispatch = useDispatch();

    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res =
            open === 'Add'
                ? await dispatch(CreateLifeCycle(data, false))
                : await dispatch(EditLifeCycle(data, one_control.id, false));
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (one_control) {
            setData(one_control);
        } else {
            setData({});
        }
    }, [one_control]);
    console.log({ one_control });
    return (
        <Modal
            title={`${open} Control Objective`}
            open={open}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="lifecycle_number">Control Objective Number</label>
                            <Input
                                type="number"
                                size="large"
                                name="lifecycle_number"
                                id="lifecycle_number"
                                onChange={handleTextChange}
                                value={data.lifecycle_number || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="lifecycle_title">Control Objective Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="lifecycle_title"
                            id="lifecycle_title"
                            onChange={handleTextChange}
                            value={data.lifecycle_title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="lifecycle_description">Control Objective Description</label>
                        <Input.TextArea
                            type="text"
                            size="large"
                            name="lifecycle_description"
                            id="lifecycle_description"
                            onChange={handleTextChange}
                            value={data.lifecycle_description || ''}
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default ObjectiveModal;
